<template>
  <div
    :id="[generateDynamicID]"
    :class="[
      { 'ab-sticky-top': isStickyTop, 'ab-sticky-bottom': isStickyBottom },
    ]"
    ref="contactFormBarRef"
  >
    <div class="ab-container ab-contactform-container">
      <!-- Hide and Show bar based on the delay and timer -->
      <div
        v-if="!isHidden && showBar"
        class="ab-flex-container ab-bg-Repeat-Cover"
        :class="[addPatternClass]"
        :style="{ background: barBackground }"
      >
        <div
          class="ab-flex-item ab-content"
          v-if="formStatus === FormStatusTypes.submitSuccess"
        >
          {{ value.configs.contactForm.successMessage }}
        </div>
        <form
          class="ab-flex-item ab-content form-input-container"
          @submit.prevent="subscribe()"
          v-if="formStatus === FormStatusTypes.unSubmitted"
          name="ab-contact-form"
        >
          <!-- Inner HTML content to be rendered -->
          <div class="ab-contact-form-input">
            <input
              type="text"
              :placeholder="value.configs.contactForm.placeholders.firstName"
              v-model="firstName"
              name="given-name"
              required
              autoCapitalize="words"
              autoComplete="given-name"
              enterkeyhint="next"
              autofocus
              spellcheck="false"
              v-on:keyup.enter.stop="focusEmail()"
            />
            <input
              type="email"
              id="input"
              v-model="email"
              name="email"
              :placeholder="value.configs.contactForm.placeholders.email"
              ref="email"
              required
              autoComplete="email"
              enterkeyhint="send"
              v-on:keyup.enter="subscribe()"
            />
            <!-- <span v-if="email && !isValidEmail" class="error-message">Please enter a valid email address</span> -->
          </div>

          <!-- When call acton type is button and related effects and styles -->
          <button
            type="submit"
            v-if="isButton"
            :class="{
              'ab-preview-button': isButton,
              'ab-wiggle-effect': value.configs.callToAction.isWiggle,
            }"
            :style="{
              background: value.configs.callToAction.bg,
              color: value.configs.callToAction.fontColor,
            }"
          >
            {{ value.configs.callToAction.text }}
          </button>

          <!-- When call action type is link and its related styles -->
          <div v-if="isLink" class="ab-link-action">
            <a
              :style="{
                color: value.configs.callToAction.fontColor,
                'border-bottom': `2px solid ${value.configs.callToAction.fontColor}`,
              }"
              @click="subscribe()"
              >{{ value.configs.callToAction.text }}</a
            >
          </div>
        </form>
        <!-- Show or hide Close icon -->
        <div
          class="ab-flex-item ab-close-icon"
          v-if="value.configs.displayConfig.showCloseIcon"
          @click="hideBar()"
        >
          <svg
            width="8"
            height="8"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              :style="{ fill: value.configs.displayConfig.iconColor }"
              d="M8.32311 0.874286L7.48484 0L4.16152 3.46614L0.838201 0L-6.10352e-05 0.874286L3.32326 4.34043L-6.10352e-05 7.80657L0.838201 8.68085L4.16152 5.21471L7.48484 8.68085L8.32311 7.80657L4.99978 4.34043L8.32311 0.874286Z"
              fill="#8e2e2e"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { FormStatus } from "./form-status";
import { getBaseUrl } from "../../utils/url.util";
import { showCTA } from "../../utils/cta.util";

import { Stuck } from "stuck-js";

export default {
  name: "contactFormBar",
  components: {},
  props: {
    value: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      // value: {
      //   configs: {
      //     contactForm: {
      //       placeholders: {
      //         firstName: "First Name placeholder",
      //         email: "Email placeholder",
      //       },
      //       successMessage: "",
      //     },
      //     displayConfig: {
      //       barBackground: "Solid",
      //       backgroundColor: "#7B61FF",
      //       barPosition: "Top",
      //       showCloseIcon: true,
      //       iconColor: "#FFFFFF",
      //       stickyBar: true,
      //       pattern: "Pattern 1",
      //     },
      //     callToAction: {
      //       type: "Button",
      //       category: "link",
      //       show: true,
      //       text: "Learn More",
      //       bg: "#F8F8F8",
      //       fontColor: "#4F4F4F",
      //       link: "",
      //       isWiggle: true,
      //     },
      //     delayTimer: {
      //       show: true,
      //       showBar: 0,
      //       timer: 4,
      //     },
      //     targetPageAndPlatform: {
      //       page: ["All"],
      //       platform: ["All"],
      //     },
      //   }
      // },
      Position: {
        top: "top",
        bottom: "bottom",
      },
      barData: null,
      displayBar: true,
      isHidden: false,
      showBar: true,
      email: "",
      firstName: "",
      formStatus: FormStatus.unSubmitted,
      FormStatusTypes: FormStatus,
      statusStoragePrefix: 'ab-contact-',
      timeIntervalRef: {
        showBarOnDelayRef: null,
        showBarAfterTimerRef: null,
      },
    };
  },
  computed: {
    /**
     * Computed method to check if type is button and supposed to show
     */
    isButton() {
      return showCTA("button", this.value.configs.callToAction);
    },
    /**
     * Computed method to check if type is link and supposed to show
     */
    isLink() {
      return showCTA("link", this.value.configs.callToAction);
    },
    /**
     * Computed method to check if Bar background is solid and add respective background color
     */
    barBackground() {
      return this.value.configs.displayConfig.barBackground.toLowerCase() ==
        "solid"
        ? this.value.configs.displayConfig.backgroundColor
        : "";
    },
    /**
     * Computed method to check if Bar background is pattern and generate respective pattern names for binding classes
     */
    addPatternClass() {
      let patternName;
      if (
        this.value.configs.displayConfig.barBackground.toLowerCase() ==
        "pattern"
      ) {
        let patterntype =
          this.value.configs.displayConfig?.pattern.toLowerCase();
        let lastChar = patterntype[patterntype.length - 1];
        patternName = `pattern_${lastChar}`;
      }
      return patternName ? patternName : "no-pattern";
    },
    /**
     * Computed method to check for bar positioning
     */
    topPositioned() {
      return (
        this.value.configs.displayConfig.barPosition.toLowerCase() == "top"
      );
    },
    /**
     * Computed method to check for bar positioning
     */
    bottomPositioned() {
      return (
        this.value.configs.displayConfig.barPosition.toLowerCase() == "bottom"
      );
    },
    isStickyTop() {
      return this.value.configs.displayConfig.stickyBar &&
        this.value.configs.displayConfig.barPosition.toLowerCase() == "top"
        ? true
        : false;
    },
    isStickyBottom() {
      return this.value.configs.displayConfig.stickyBar &&
        this.value.configs.displayConfig.barPosition.toLowerCase() == "bottom"
        ? true
        : false;
    },

    generateDynamicID() {
      return `contactform-bar-ref-${this.index}`;
    },

    // getTheClosestWrapper(){
    //   return this.$refs.contactFormBarRef.closest('#ext-announcement-bar-top-view');
    // },
    /**
     * Computed method to check on link performed while clicked on link or button based on the the type
     */
    LinkActionPerfomed() {
      let content = "";
      switch (this.value.configs.callToAction.category) {
        case "link":
          content = this.value.configs.callToAction.link;
          break;
        case "email":
          content = `mailto:${this.value.configs.callToAction.link}`;
          break;
        case "mobile":
          content = `tel:${this.value.configs.callToAction.link}`;
          break;
      }
      return content;
    },
    /**
     * Computed method to check for pattern validation for email
     */
    isValidEmail() {
      return this.value.configs.email.match(this.emailPattern);
    },
  },
  mounted() {
    this.setInitialFormStatus();
    this.showBarOnDelay();

    if (this.isStickyTop) {
      new Stuck(
        [{ selector: `#contactform-bar-ref-${this.index}`, wrapper: "#app" }],
        { marginTop: 0 }
      );
    }
  },
  methods: {
    /**
     * Method to determine what should be the call of action based on different types
     * @param {*} cta : Call to Action types and actions
     */
    callAction: (cta) => {
      if (!cta) {
        return;
      }
      switch (cta.category) {
        case "email": {
          if (!cta.email) {
            break;
          }
          window.location.href = `mailto:${cta.email}`;
          break;
        }
        case "phone": {
          if (!cta.phoneNumber) {
            break;
          }
          window.open(`tel://${cta.phoneNumber}`);
          break;
        }
        case "link": {
          if (!cta.link) {
            break;
          }
          window.open(cta.link, "_blank");
          break;
        }
        default: {
          console.error("CTA category not supported");
          break;
        }
      }
    },

    focusEmail: function () {
      this.$refs.email.focus();
    },

    getStorageKey: function () {
      return `${this.statusStoragePrefix}${this.value._id}`;
    },

    setInitialFormStatus() {
      const formSubmitKey = this.getStorageKey();
      const storageStatus = localStorage.getItem(formSubmitKey);
      if (storageStatus === FormStatus.submitSuccess) {
        this.formStatus = FormStatus.submitSuccess;
      } else {
        this.formStatus = FormStatus.unSubmitted;
      }
    },

    /**
     * Subscribe when clicked on CTA
     */
    subscribe: async function () {
      if (!window.FPI) {
        this.formStatus = this.FormStatusTypes.submitSuccess;
        return;
      }
      this.formStatus = this.FormStatusTypes.submitting;
      const formSubmitKey = this.getStorageKey();
      localStorage.setItem(formSubmitKey, this.formStatus);
      const url = getBaseUrl();
      try {
        await axios.post(
          `https://${url}/ext/announcement-bar/application/api/v1/analytics`,
          {
            email: this.email,
            firstName: this.firstName,
          }
        );
        this.formStatus = this.FormStatusTypes.submitSuccess;
      } catch (e) {
        this.formStatus = this.FormStatusTypes.submitError;
      } finally {
        localStorage.setItem(formSubmitKey, this.formStatus);
      }
    },

    /**
     * Method triggered once close button is clicked
     */
    hideBar() {
      this.isHidden = true;
      this.showBarAfterTimer();
    },

    /**
     * Method to show bar after delay
     */
    showBarOnDelay() {
      if (this.value.configs.delayTimer.show === true) {
        if (this.value.configs.delayTimer.showBar > 0) {
          this.showBar = false;
          this.timeIntervalRef.showBarOnDelayRef = setTimeout(() => {
            this.showBar = true;
          }, this.value.configs.delayTimer.showBar);
        } else {
          this.showBar = true;
        }
      }
    },

    /**
     * Method to show bar after it has been closed after certain time interval
     */
    showBarAfterTimer() {
      if (this.value.configs.delayTimer.show === true) {
        this.timeIntervalRef.showBarAfterTimerRef = setTimeout(() => {
          this.isHidden = false;
        }, Number(this.value.configs.delayTimer.timer * 1000));
      }
    },
  },

  destroyed() {
    clearTimeout(this.timeIntervalRef.showBarOnDelayRef);
    clearTimeout(this.timeIntervalRef.showBarAfterTimerRef);
  },
};
</script>
<style scoped lang="scss">

</style>
